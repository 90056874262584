import * as React from 'react';
// import * as styles from './chris180.module.scss';
import WorkExample from '../../../components/WorkExample';

//Components
import Seo from '../../../components/seo';

//Images
import chrisOneEightyHeaderImage from '../../../images/work-example-headers/chris-180.png';
import usabiltyAuditPDF from '../../../images/chris-180-usability-audit.pdf'

const ChrisOneEighty = ({ location }) => {
    return (
        <WorkExample
            pageName={'chris180'}
            cardColor={'#853535'}
            title={'Chris 180'}
            subtitle={"Usability audit of a non-profit's website"}
            imageData={chrisOneEightyHeaderImage}
            imageAlt={"Chris 180"}
            links={[
                {text: "View the report", url: usabiltyAuditPDF, external: true}
            ]}
            location={location}
        >

            <Seo
                title={"Chris 180"}
                description={"Usability audit of a non-profit's website"}
            />

            <h2>About the Project</h2>
            <p>Chris 180 is an Atlanta-based nonprofit organization that
                provides trauma informed mental health and housing services to children
                and young adults.  They have grown significantly in recent years to include
                the services of multiple different organizations that previously operated
                independently.  Because of this, their website has grown greatly in scale
                and is in need of some reorganization to make it more helpful for its users.
            </p>
            <p>
                I was tasked with auditing the site to identify issues with usability and
                make suggestions for a future redesign of the site.  Part of my job was
                making a case for the need for a redesign since the visual communications
                director was in need of approval and funding from leadership to make changes.</p>

            <h2>What I did</h2>
            <ul>
                <li>Performed a UX audit of a ~80 page site</li>
                <li>Provided actionable advice for website redesign</li>
            </ul>
        </WorkExample>
    )
}

export default ChrisOneEighty;